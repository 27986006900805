import { Routes, Route } from "react-router-dom";

import DashboardPage from "pages";
import LibraryPage from "pages/library";
import OauthCallBackPage from "pages/oauth-callback/:source";
import EmailTestPage from "pages/email-test";
import DataCrawlerPage from "pages/data-crawler";
import UserManagamentPage from "pages/user-management";
import DeploymentsPage from "pages/deployments";
import LoginPage from "pages/login";
import OneTimeCodePage from "pages/one-time-code";
import ExcelWorkersPage from "pages/excel-workers";

const PageComponentOnRoute = () => (
  <Routes>
    <Route path="/oauth-callback/:source" element={<OauthCallBackPage />} />
    <Route path="/" element={<DashboardPage />} />
    <Route path="/email-test" element={<EmailTestPage />} />
    <Route path="/data-crawler" element={<DataCrawlerPage />} />
    <Route path="/user-management" element={<UserManagamentPage />} />
    <Route path="/deployments" element={<DeploymentsPage />} />
    <Route path="/library" element={<LibraryPage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/one-time-code" element={<OneTimeCodePage />} />
    <Route path="/excel-workers" element={<ExcelWorkersPage />} />
  </Routes>
);

export default PageComponentOnRoute;

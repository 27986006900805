import { useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";

const Container = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
  width: 100%;
`;

const Input = styled.input`
  color: ${props => props.theme.color.closest};
  background-color: ${props => props.theme.color.furthest};
  width: 100%;
  display: block;
  border: 1px solid ${props => (props.isRed ? props.theme.color.error : props.theme.color.closer1)};
  padding: 10px 10px 10px 15px;
  border-radius: 60px;
  :focus {
    border-color: ${props => props.theme.color.primary};
  }
  transition: border-color 0.2s;
`;

const InputTitle = styled.label`
  display: block;
  color: ${props => (props.isRed ? props.theme.color.error : props.theme.color.closest)};
  font-weight: bold;
  padding-bottom: 10px;
`;

const TextInput = ({
  isFocussed,
  value = "",
  title,
  type = "text",
  onNewInput,
  isRequired,
  dataTutorialId,
  placeholder,
  className,
  min,
  max,
  isDisabled,
  isRed,
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (isFocussed) {
      inputRef?.current?.focus();
      return;
    }
  }, [isFocussed]);

  return (
    <Container data-tutorial-id={dataTutorialId} isDisabled={isDisabled}>
      {title && <InputTitle isRed={isRed}>{title}</InputTitle>}
      <Input
        ref={inputRef}
        className={className}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={e => onNewInput(e.target.value)}
        required={isRequired}
        min={min}
        max={max}
        isRed={isRed}
      />
    </Container>
  );
};

export default TextInput;

import { useRef, useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
`;

const Input = styled.input`
  ${props => props.isHidden && "display: none;"}
  width: 150px;
  font-family: "Montserrat", sans-serif;
  border: none;
  padding: 0px;
  padding-left: 10px;
  ::file-selector-button {
    display: none;
  }
  color: ${props => props.theme.color.closest};
`;

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const UploadImage = styled.div`
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAABmUlEQVRIie2WTygEcRTHPzu0hZPigNOKAxcppeTPmYuL/Cnl6k+G2BS5KpIwF87KwcVBiZPTXnBRohxEiYtSpAgNvXqzTbs7zcwaN9/Le7+Z995nf+83b2ZjsfVvQsgEZoFtIClptumfXBgCMAmsqj8NxNT6ysgD4GgKWIkKMpwDkAYZFgu/hUwAG+rbwJ3698CH+nOGxXK+EGnRmmvdAhyofwk0A6+6ThqWd+u8IKMZLeoCjoEaXdcCZ0AT4Dye0rrFMJBxl9/v2oHzNNpqr4B2V+xMGMiQzkIfsOO67hRPD5dtkgLaNL47VzGvOTkBBj3uZUlBKa/7QefkV/qHhJIcfBnQA+wBDz7JRRnW+9dblAK9wL5AtoBOYADo8Mn9UlsQYCebCrkQSL1erAyQOKIvzN0AsdVq6wTyrIuXAInnwFiAOFx1nwz39P6VBBLX2u8RM97Uxg1X8YqIIVVqP+VMjoBGIAGcAtf6/Q4kw8qKkvYLQGqKDuXfSjlwA5REvBNHCdnJI9AALAGtQHEEheUI5E0+b5vc/gAfBU+f3PV7LQAAAABJRU5ErkJggg==);
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
`;

const ChooseFileFakeButton = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  color: ${props => props.theme.color.primary};
  background-color: white;
  border-radius: 40px;
  padding: 10px 25px;
  width: max-content;
  border: 2px solid ${props => props.theme.color.primary};

  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.5};

  color: ${props => props.theme.color.primary};
  background-color: ${props => props.theme.color.furthest};

  :hover {
    color: white;
    background-color: ${props => props.theme.color.primary};
    ${UploadImage} {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFYSURBVHgB7ZU/L0RBFMXvbGQTVBIKf5qNQiWxDc3rVAotH4Eo8AG0EoJSodf4AiLiTzZREQmJRIEQkZAoFYjsjnM5xXjZZd5781R7kt/O3Lt3ztnsbN6KJJC1dhY8glXJQzCetz+1JiFVJyBsEIym7e9alCyCwZxjVgX33D+Ad+e9FUmjOl/RCNjgfhcMgZfUXx0OzMQCxtjfZ33LegDUnLmlJCGXzsFJp19h78bpRc5sLUnIMNgEE7H+Ic2uY/2I8+OSVY1C/lJB/kHNkGQhuMRO+/3o6PGYb42tDQW/DjAF+rTY5i+m4nHwiLNPHrNbnL3Q4o7FlcfBQbAORj1mT+hb1eKcxakEFPz26PusF28lZ2lIkfs3CatXrsWCY94tYdXL9aMFLwegDEp6WVj1CWskvSwDyqx3DIy7sNH/h3bJR6WvT4ygfizLIAJtkl16BcdgwRhz9glaFML/K+s8lgAAAABJRU5ErkJggg==);
    }
  }
`;

const InputTitle = styled.div`
  color: ${props => props.theme.color.closest};
  font-weight: bold;
  padding-bottom: 10px;
`;

const ErrorText = styled.div`
  padding-top: 10px;
  color: ${props => props.theme.color.error};
`;

const FileInput = ({
  onFileSelect,
  title,
  fileName,
  buttonText = "Upload a file",
  isShowingFileChosenText = true,
  className,
  dataTutorialId,
  maxFileSizeBytes = 1e9,
  isDisabled,
}) => {
  const inputRef = useRef();
  const [error, setError] = useState("");

  useEffect(() => {
    if (!fileName && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [fileName]);

  return (
    <Container data-tutorial-id="choose-file-button" isDisabled={isDisabled}>
      {title && <InputTitle>{title}</InputTitle>}
      <Label>
        <ChooseFileFakeButton className={className}>
          <UploadImage />
          {buttonText}
        </ChooseFileFakeButton>
        {onFileSelect && (
          <Input
            ref={inputRef}
            data-tutorial-id={dataTutorialId}
            isHidden={!isShowingFileChosenText}
            type="file"
            onChange={e => {
              setError("");
              const file = e.target.files[0];
              if (file?.size > maxFileSizeBytes) {
                setError("Max file size is 1GB");
                inputRef.current.value = "";
                onFileSelect(null);
                return;
              }
              onFileSelect(file);
            }}
          />
        )}
      </Label>
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

export default FileInput;

import usePollFileStatus from "api/services/usePollFileStatus";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CardContainer = styled(Link)`
  ${props => props.$isDisabled && "pointer-events: none; opacity: 0.3;"}
  display: block;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  cursor: pointer;
  height: 70px;
  padding: 10px;
  background-color: ${props => props.theme.color.closer0};
  border-radius: 10px;

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const CardTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const FileCard = ({ file }) => {
  const fileStatus = usePollFileStatus(file?.id, file?.status);

  return (
    <CardContainer to={`/file/${file?.id}`} $isDisabled={fileStatus !== "READY"}>
      <CardTitle>{file?.fileName}</CardTitle>
      {fileStatus}
    </CardContainer>
  );
};

export default FileCard;

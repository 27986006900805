import { ASSISTANT_EMAIL, CUSTOMER_EMAIL, DEMO_EMAIL, BOLTZFLOW_EMAIL } from "api/services/authenticationService";
import { CenteredWithSideNavLayout, Gap } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import AuthoriseAdminEmail from "components/widgets/AuthoriseAdminEmail";

const DashboardPage = () => {
  return (
    <CenteredWithSideNavLayout>
      <BigTitle>Email bots</BigTitle>
      <Gap />
      <AuthoriseAdminEmail email={DEMO_EMAIL} />
      <Gap />
      <AuthoriseAdminEmail email={CUSTOMER_EMAIL} />
      <Gap />
      <AuthoriseAdminEmail email={ASSISTANT_EMAIL} />
      <Gap />
      <AuthoriseAdminEmail email={BOLTZFLOW_EMAIL} />
    </CenteredWithSideNavLayout>
  );
};

export default DashboardPage;

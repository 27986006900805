import { apiPost } from "api/api-http-methods";
import { errorTypes } from "api/error-handling";
import { zapiGet, zapiPost } from "api/zapi";

export const getLoginTokens = async (userName, password) => {
  const { data, error } = await apiPost(
    "/playground/authentication-service/login",
    {},
    {
      userName,
      password,
    }
  );
  if (error) {
    return { data, error: { type: errorTypes.LOGIN_ERROR } };
  }
  return { data, error: null };
};

export const gmailLogin = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/login", params);
  return { data, error };
};

export const getOauthCallbackBzAdmin = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/oauth-callback/bz-admin", params);
  return { data, error };
};

export const getOauthCallbackCustomerAdmin = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/oauth-callback/customer-admin", params);
  return { data, error };
};

export const getOauthCallbackAssistantAdmin = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/oauth-callback/assistant-admin", params);
  return { data, error };
};

export const getOauthCallbackBoltzflowAdmin = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/oauth-callback/boltzflow-admin", params);
  return { data, error };
};


export const getTokenBzAdmin = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/token/bz-admin", params);
  return { data, error };
};

export const getTokenOcrAdmin = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/token/ocr/boltzflow-admin", params);
  return { data, error };
};

export const getTokenCustomerAdmin = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/token/customer-admin", params);
  return { data, error };
};

export const getTokenAssistantAdmin = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/token/assistant-admin", params);
  return { data, error };
};

export const sendEmailBzAdmin = async body => {
  const { data, error } = await zapiPost("/playground/api/v1/emails/bz-admin", null, body);
  return { data, error };
};

export const sendEmailCustomerAdmin = async body => {
  const { data, error } = await zapiPost("/playground/api/v1/emails/customer-admin", null, body);
  return { data, error };
};

export const sendEmailAssistantAdmin = async body => {
  const { data, error } = await zapiPost("/playground/api/v1/emails/assistant-admin", null, body);
  return { data, error };
};

export const sendEmailBoltzflowAdmin = async body => {
  const { data, error } = await zapiPost("/playground/api/v1/emails/boltzflow-admin", null, body);
  return { data, error };
};

export const getVerifyOneTimeCode = async (userId, params) => {
  const { data, error } = await zapiGet(`/playground/authentication-service/users/2fa/${userId}/verify`, params);
  return { data, error };
};

import { getHandlingFromError } from "api/error-handling";
import * as backend from "api/backend/devEndpoints";

export const getExcelWorkerConfigs = async () => {
  const { data, error } = await backend.getExcelWorkerConfigs();
  return { data, error: getHandlingFromError(error) };
};

export const getAllExcelWorkers = async () => {
  const { data: workers, error } = await backend.getExcelWorkers();
  if (error) {
    return { data: [], error: getHandlingFromError(error) };
  }

  const { data: dedicatedWorkers, error: errDedicated } = await backend.getExcelWorkersDedicated();
  if (errDedicated) {
    return { data: [], error: getHandlingFromError(error) };
  }

  return { data: [...workers, ...dedicatedWorkers], error: getHandlingFromError(null) };
};

export const getExcelWorkers = async () => {
  const { data, error } = await backend.getExcelWorkers();
  return { data, error: getHandlingFromError(error) };
};

export const getExcelWorkersDedicated = async () => {
  const { data, error } = await backend.getExcelWorkersDedicated();
  return { data, error: getHandlingFromError(error) };
};

export const getNodePools = async () => {
  const { data, error } = await backend.getNodePools();
  return { data, error: getHandlingFromError(error) };
};

export const patchExcelWorkerConfig = async (params, body) => {
  const { error } = await backend.patchExcelWorkerConfig(params, body);
  return { error: getHandlingFromError(error) };
};

export const deleteKillWorker = async workerId => {
  const { error } = await backend.deleteKillWorker(workerId);
  return { error: getHandlingFromError(error) };
};

export const postDedicatedExcelWorkerForUser = async userId => {
  const { error } = await backend.postDedicatedExcelWorkerForUser(userId);
  return { error: getHandlingFromError(error) };
};

export const deleteKillDedicatedWorker = async userId => {
  const { error } = await backend.deleteKillDedicatedWorker(userId);
  return { error: getHandlingFromError(error) };
};

import { getDeployableServices } from "api/services/deployment";
import { format } from "date-fns";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import Tooltip from "components/ui/Tooltip";
import { getShortCommitAndBranchNameFromTag } from "utils/common";

const Table = styled.div`
  display: grid;
  width: max-content;
  grid-template-columns: repeat(${props => props.numColumns || 3}, max-content);
  border-left: 1px solid ${props => props.theme.color.closer1};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const TableCell = styled.div`
  width: auto;
  max-height: 100px;
  overflow: hidden;
  padding: 5px;
  border-right: 1px solid ${props => props.theme.color.closer1};
  border-top: 1px solid ${props => props.theme.color.closer1};

  ${props => props.isDisabled && "opacity: 0.3; pointer-events: none;"}
`;

const TableCellMonospace = styled(TableCell)`
  font-family: "Courier New", Courier, monospace;
`;

const GreenStatusCell = styled(TableCell)`
  min-width: 100px;
  color: ${props => props.theme.color.success};
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const YellowStatusCell = styled(GreenStatusCell)`
  color: ${props => props.theme.color.in_progress};
`;

const StatusCell = ({ conditions }) => {
  const lastCondMessage = conditions?.[conditions?.length - 1]?.message;

  if (lastCondMessage?.includes("progressing")) {
    return <YellowStatusCell>IN PROGRESS</YellowStatusCell>;
  }
  return <GreenStatusCell>DEPLOYED</GreenStatusCell>;
};

const TableColumnHeader = styled.div`
  font-weight: bold;
  border-right: 1px solid ${props => props.theme.color.closer1};
  border-top: 1px solid ${props => props.theme.color.closer1};
  padding: 10px 5px;
`;

const Container = styled.div``;

const ErrorMsg = styled.div`
  color: ${props => props.theme.color.error};
`;

const ConditionLineContainer = styled.div`
  display: grid;
  grid-template-columns: 100px 50px auto;
  padding-bottom: 5px;
`;

const DeployableServicesTable = ({ productEnvName }) => {
  const [deployableServices, setDeployableServices] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    doPopulateDeployableServices();
  }, [productEnvName]);

  const doPopulateDeployableServices = async () => {
    const { data, error: errGetServices } = await getDeployableServices(productEnvName);
    setError(errGetServices);
    setDeployableServices(data);
  };

  if (error) {
    return (
      <Container>
        <ErrorMsg>{error?.message}</ErrorMsg>
      </Container>
    );
  }

  if (!deployableServices?.length) {
    return "Loading...";
  }

  const columnNames = ["name", "deployedAt", "status", "replicas", "tag", "cluster", "deployedBy"];

  return (
    <Container>
      <Table numColumns={columnNames?.length}>
        {columnNames?.map(colName => (
          <TableColumnHeader key={colName}>{colName}</TableColumnHeader>
        ))}
        {deployableServices.map((row, rowNum) =>
          columnNames?.map(colName => {
            if (colName === "deployedAt" && row["deployedAt"]) {
              return (
                <TableCellMonospace key={`${rowNum}-${colName}`}>
                  {format(new Date(`${row[colName]} UTC`), "yyyy-MM-dd HH:mm:ss")}
                </TableCellMonospace>
              );
            }

            if (colName === "status") {
              return (
                <Tooltip
                  key={`${rowNum}-${colName}`}
                  placement="right"
                  title={
                    <div>
                      {row[colName]?.conditions?.map((cond, i) => (
                        <ConditionLineContainer key={i}>
                          <div>{cond?.type}</div>
                          <div>{cond?.status}</div>
                          <div>{cond?.message}</div>
                        </ConditionLineContainer>
                      ))}
                    </div>
                  }
                >
                  <StatusCell conditions={row[colName]?.conditions} />
                </Tooltip>
              );
            }

            if (colName === "tag") {
              return (
                <TableCellMonospace key={`${rowNum}-${colName}`}>
                  {getShortCommitAndBranchNameFromTag(row?.tag)}
                </TableCellMonospace>
              );
            }

            if (colName === "replicas") {
              return (
                <TableCell isDisabled={!row?.manualScalingAllowed} key={`${rowNum}-${colName}`}>
                  {row?.[colName]}
                </TableCell>
              );
            }

            const cellContent = typeof row[colName] === "object" ? JSON.stringify(row[colName]) : row[colName];
            return <TableCell key={`${rowNum}-${colName}`}>{cellContent}</TableCell>;
          })
        )}
      </Table>
    </Container>
  );
};

export default DeployableServicesTable;

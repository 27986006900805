import * as backend from "api/backend/projectServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";

const placeholderUserProfile = {
  image: "/images/avatar-placeholder.jpeg",
};

export const getUserProfileByUserName = async userName => {
  const { data, error } = await backend.getUserProfileByUserName(userName);
  if (error) {
    if (error?.message?.includes("UserProfile not found")) {
      return { data: placeholderUserProfile, error: null };
    }

    return { data: null, error: getHandlingFromError(error) };
  }
  return { data: data.image ? data : { ...data, image: "/images/avatar-placeholder.jpeg" }, error };
};

import { useState } from "react";
import styled from "styled-components";

import Button from "components/ui/Button";
import FileCard from "components/ui/FileCard";
import FileInput from "components/ui/FileInput";
import TextInput from "components/ui/TextInput";
import { CardsContainer } from "components/Layout";
import Table from "components/widgets/Table";
import TextResult from "components/widgets/TextResult";
import { BigTitle, SmallTitle } from "components/ui/Text";
import Modal from "components/ui/Modal";

const SOME_TABLE_ROWS = [
  {
    1: "",
    2: "¢ Stylish and portable design: The Yoga Slim 7 brings a sophistication and portability together with a",
    ID: 1,
  },
  {
    1: "",
    2: "¢ Brilliant audio-visuals: Enjoy vibrant colour and clarity with a 14 Inch FHD display optimised with Dolby",
    ID: 2,
  },
  {
    1: "Roll over image to zoom in",
    2: "¢ The power to do more: Yoga makes light work of travel, weighing just 1.36kg and up to 15hrs battery",
    ID: 3,
  },
];

const Container = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
`;

const ComponentBoxBorder = styled.div`
  border: 1px dashed #00000015;
  width: ${props => props.width || "max-content"};
`;

const ComponentTitle = styled.div`
  color: grey;
  font-size: 18px;
  padding-bottom: 5px;
`;

const ComponentBoxContainer = styled.div``;

const GreyRect = styled.div`
  height: 70px;
  width: 120px;
  background-color: lightgrey;
`;

const ComponentBox = ({ title, children, width }) => (
  <ComponentBoxContainer>
    <ComponentTitle>{title}</ComponentTitle>
    <ComponentBoxBorder width={width}>{children}</ComponentBoxBorder>
  </ComponentBoxContainer>
);

const ModalContent = styled.div`
  width: 600px;
  height: 400px;
  padding: 10px;
`;

const Section = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  width: max-content;
  width: 100%;
  overflow-x: scroll;
`;

const LibraryPage = () => {
  const [textInputText, setTextInputText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Container>
      <Section>
        <ComponentBox title="BigTitle">
          <BigTitle>This is a big title</BigTitle>
        </ComponentBox>
        <ComponentBox title="SmallTitle">
          <SmallTitle>This is a small title</SmallTitle>
        </ComponentBox>
      </Section>
      <Section>
        <ComponentBox title="Button">
          <Button value="Click me" />
        </ComponentBox>
        <ComponentBox title="FileInput">
          <FileInput title="A file upload" onFileSelect={newFile => {}} />
        </ComponentBox>
        <ComponentBox title="TextInput">
          <TextInput value={textInputText} title="Name" onNewInput={newText => setTextInputText(newText)} />
        </ComponentBox>
      </Section>
      <Section>
        <ComponentBox title="FileCard">
          <FileCard file={{ fileName: "cadeler2020_2pages.pdf", status: "READY", id: "62bec4506075a27be0545abf" }} />
        </ComponentBox>
        <ComponentBox title="CardsContainer">
          <CardsContainer>
            <GreyRect />
            <GreyRect />
            <GreyRect />
            <GreyRect />
          </CardsContainer>
        </ComponentBox>
      </Section>
      <Section>
        <ComponentBox title="Table" width="100%">
          <Table name="A table" rows={SOME_TABLE_ROWS} />
        </ComponentBox>
        <ComponentBox title="TextResult" width="100%">
          <TextResult textItem={{ content: "This is text scanned from a doc" }} />
        </ComponentBox>
      </Section>
      <Section>
        <ComponentBox title="Modal">
          <button onClick={() => setIsModalOpen(true)}>trigger modal</button>
          <Modal title="Modal Title" open={isModalOpen} handleClose={() => setIsModalOpen(false)}>
            <ModalContent>This is modal content</ModalContent>
          </Modal>
        </ComponentBox>
      </Section>
    </Container>
  );
};

export default LibraryPage;

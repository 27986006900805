import { zapiGet, zapiPost } from "api/zapi";

export const getProductEnvironments = async () => {
  const { data, error } = await zapiGet("/playground/api/v1/deployment/product-environments");
  return { data, error };
};

export const getDeployableServices = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/deployment/deployable-services", params);
  return { data, error };
};

export const getDeploymentActions = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/deployment/product-environments", params);
  return { data, error };
};

export const getAvailableTags = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/deployment/available-tags", params);
  return { data, error };
};

export const postDeploymentAction = async body => {
  const { data, error } = await zapiPost("/playground/api/v1/deployment/deployment-actions", null, body);
  return { data, error };
};

export const getModelVersions = async params => {
  const { data, error } = await zapiGet("/playground/api/v1/deployment/ml-model_versions", params);
  return { data, error };
};

import styled from "styled-components";
import { useState, useEffect, Fragment } from "react";

import {
  deleteKillDedicatedWorker,
  deleteKillWorker,
  getAllExcelWorkers,
  getExcelWorkerConfigs,
  getNodePools,
  patchExcelWorkerConfig,
  postDedicatedExcelWorkerForUser,
} from "api/services/dev";
import { getAvailableTagsForServiceImageName } from "api/services/deployment";

import { CenteredWithSideNavLayout } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import EditableJson from "components/ui/EditableJson";
import usePollExcelWorkers from "api/services/usePollExcelWorkers";

const getFieldNameOfExcelWorkerConfigToInput = ({ nodePools = {}, tags = [] }) => {
  const fieldNameToInput = {
    cpu: ({ value, onChangeValue }) => (
      <input type="number" value={value} onChange={e => onChangeValue(e.target.value)} />
    ),
    isAutoShutdownOn: ({ value, onChangeValue }) => (
      <input type="checkbox" checked={value} onChange={e => onChangeValue(e.target.checked)} />
    ),
    image: ({ value, onChangeValue }) => (
      <select value={value} onChange={e => onChangeValue(e.target.value)} style={{ width: "320px" }}>
        {tags?.map(tag => (
          <option key={tag?.tag}>{tag?.tag}</option>
        ))}
      </select>
    ),
    minimumIdleWorker: ({ value, onChangeValue }) => (
      <input type="number" value={value} onChange={e => onChangeValue(parseInt(e.target.value))} />
    ),
    poolName: ({ value, onChangeValue }) => (
      <select value={value} onChange={e => onChangeValue(e.target.value)}>
        {Object.keys(nodePools)?.map(clusterName => (
          <optgroup key={clusterName} label={clusterName}>
            {nodePools[clusterName]?.map(poolName => (
              <option key={poolName}>{poolName}</option>
            ))}
          </optgroup>
        ))}
      </select>
    ),
    memory: ({ value, onChangeValue }) => <input value={value} onChange={e => onChangeValue(e.target.value)} />,
  };

  return fieldNameToInput;
};

const TABLE_FIELDS = ["id", "userId", "isDedicated", "isIdle", "lastUsedAt", ""];

const StyledCenteredWithSideNavLayout = styled(CenteredWithSideNavLayout)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-content: start;
`;

const SmallTitle = styled.div`
  font-size: 20px;
`;

const BigTitleTwoColumn = styled(BigTitle)`
  grid-column: span 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.numColumns}, auto);
  grid-column: span 2;
  border: 0.5px solid ${props => props.theme.color.closer1_5};\
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const Cell = styled.div`
  padding: 5px 10px;
  border: 0.5px solid ${props => props.theme.color.closer1_5};
`;

const ColoredCell = styled(Cell)`
  color: ${props => (props.isGreen ? props.theme.color.success : props.theme.color.error)};
`;

const HeaderCell = styled(Cell)`
  font-weight: 600;
`;

const Button = styled.button`
  font-family: "Montserrat";
`;

const InputAndButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
`;

const ErrMsg = styled.div`
  min-height: 20px;
  grid-column: span 2;
  color: ${props => props.theme.color.error};
`;

const BlueDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`;

const ExcelWorkersPage = () => {
  const [excelWorkerConfigs, setExcelWorkerConfigs] = useState([]);
  const [nodePools, setNodePools] = useState({});
  const [error, setError] = useState(null);
  const [isPatchingConfig, setIsPatchingConfig] = useState(false);
  const [isLoadingWorkers, setIsLoadingWorkers] = useState(false);
  const [tags, setTags] = useState([]);
  const [userId, setUserId] = useState(null);

  const [excelWorkers, setExcelWorkers, isFetchingExcelWorkers] = usePollExcelWorkers();

  useEffect(() => {
    doPopulateNodePools();
    doPopulateExcelWorkerConfigs();
    doPopulateTagsForExcelWorkerConfigs();
  }, []);

  const doPopulateExcelWorkerConfigs = async () => {
    const { data } = await getExcelWorkerConfigs();
    setExcelWorkerConfigs(data);
  };

  const doPopulateNodePools = async () => {
    const { data } = await getNodePools();
    setNodePools(data);
  };

  const doPatchExcelWorkerConfig = async (name, fieldsToPatch) => {
    setError(null);
    setIsPatchingConfig(true);
    const { error } = await patchExcelWorkerConfig({ name }, fieldsToPatch);
    setError(error);
    setIsPatchingConfig(false);
  };

  const doDeleteKillWorker = async workerId => {
    setError(null);
    setIsLoadingWorkers(true);
    const { error } = await deleteKillWorker(workerId);
    setError(error);

    const { data } = await getAllExcelWorkers();
    setExcelWorkers(data);
    setIsLoadingWorkers(false);
  };

  const doDeleteKillDedicatedWorker = async userId => {
    setError(null);
    setIsLoadingWorkers(true);
    const { error } = await deleteKillDedicatedWorker(userId);
    setError(error);

    const { data } = await getAllExcelWorkers();
    setExcelWorkers(data);
    setIsLoadingWorkers(false);
  };

  const doPopulateTagsForExcelWorkerConfigs = async () => {
    const { data } = await getAvailableTagsForServiceImageName("ocr_OcrSolutionCCExcelWorker");
    setTags(data);
  };

  const doCreateExcelWorkerForUser = async () => {
    setError(null);
    setIsLoadingWorkers(true);
    const { error } = await postDedicatedExcelWorkerForUser(userId);
    setError(error);

    const { data } = await getAllExcelWorkers();
    setExcelWorkers(data);
    setIsLoadingWorkers(false);
  };

  const fieldNameToInput = getFieldNameOfExcelWorkerConfigToInput({ nodePools, tags });
  const doesUserIdAlreadyHaveDedicatedWorker = excelWorkers
    ?.filter(worker => worker.isDedicated)
    ?.some(worker => worker?.userId === userId);

  return (
    <StyledCenteredWithSideNavLayout>
      <BigTitleTwoColumn>Excel worker configs</BigTitleTwoColumn>
      <SmallTitle>Dedicated</SmallTitle>
      <SmallTitle>Dynamic</SmallTitle>
      <EditableJson
        isDisabled={isPatchingConfig}
        jsonToEdit={excelWorkerConfigs?.find(config => config?.name === "DEDICATED_BOLTZFLOW_WORKER")}
        fieldNameToInput={fieldNameToInput}
        onPressSave={updatedConfig => doPatchExcelWorkerConfig("DEDICATED_BOLTZFLOW_WORKER", updatedConfig)}
      />
      <EditableJson
        isDisabled={isPatchingConfig}
        jsonToEdit={excelWorkerConfigs?.find(config => config?.name === "BOLTZFLOW_WORKER")}
        fieldNameToInput={fieldNameToInput}
        onPressSave={updatedConfig => doPatchExcelWorkerConfig("BOLTZFLOW_WORKER", updatedConfig)}
      />

      <ErrMsg>{error && JSON.stringify(error)}</ErrMsg>

      <BigTitleTwoColumn>
        Excel workers
        <BlueDot isVisible={isFetchingExcelWorkers} />
      </BigTitleTwoColumn>
      <InputAndButtonContainer>
        <input placeholder="User ID" value={userId} onChange={e => setUserId(e.target.value)} />
        <Button disabled={!userId || doesUserIdAlreadyHaveDedicatedWorker} onClick={doCreateExcelWorkerForUser}>
          Create & Assign Dedicated Worker
        </Button>
        <div></div>
      </InputAndButtonContainer>
      <TableContainer numColumns={TABLE_FIELDS?.length} isDisabled={isLoadingWorkers}>
        {TABLE_FIELDS.map(fieldName => (
          <HeaderCell key={`header-${fieldName}`}>{fieldName}</HeaderCell>
        ))}
        {excelWorkers?.map(excelWorker => (
          <Fragment key={excelWorker?.id}>
            {TABLE_FIELDS.map(fieldName => {
              if (fieldName === "") {
                return (
                  <Cell key={fieldName}>
                    <Button
                      onClick={() => {
                        if (excelWorker?.isDedicated) {
                          doDeleteKillDedicatedWorker(excelWorker?.userId);
                          return;
                        }
                        doDeleteKillWorker(excelWorker?.id);
                      }}
                    >
                      Kill
                    </Button>
                  </Cell>
                );
              }

              if (typeof excelWorker?.[fieldName] === "boolean") {
                return (
                  <ColoredCell key={fieldName} isGreen={excelWorker?.[fieldName]}>
                    {JSON.stringify(excelWorker?.[fieldName])}
                  </ColoredCell>
                );
              }

              return <Cell key={fieldName}>{excelWorker?.[fieldName]}</Cell>;
            })}
          </Fragment>
        ))}
      </TableContainer>
    </StyledCenteredWithSideNavLayout>
  );
};

export default ExcelWorkersPage;

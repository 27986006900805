import { getAllExcelWorkers } from "api/services/dev";
import { useState, useEffect } from "react";

const usePollExcelWorkers = () => {
  const [excelWorkers, setExcelWorkers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    doPopulateExcelWorkers();
    const intervalId = setInterval(doPopulateExcelWorkers, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const doPopulateExcelWorkers = async () => {
    setIsLoading(true);
    const { data } = await getAllExcelWorkers();
    setExcelWorkers(data);
    setIsLoading(false);
  };

  return [excelWorkers, setExcelWorkers, isLoading];
};

export default usePollExcelWorkers;

import { useState, useEffect } from "react";
import styled from "styled-components";

import GmailButton from "components/ui/GmailButton";
import { getRedirectUrlToGoogleLogin, getIsEmailAuthorised, sendAdminEmail } from "api/services/authenticationService";
import TextInput from "components/ui/TextInput";

const doGmailLoginAndRedirectToOauth = async email => {
  const { data: redirectUrl } = await getRedirectUrlToGoogleLogin();
  sessionStorage.setItem("tempAdminEmailToAuthorise", email);
  window.location = redirectUrl;
};

const Container = styled.div`
  width: 800px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr auto;
  align-items: end;
`;

const StatusMsg = styled.div``;

const BotTitle = styled.div`
  width: 225px;
  font-size: 18px;
  align-self: center;
`;

const AuthoriseAdminEmail = ({ email }) => {
  const [isEmailAuthorised, setIsEmailAuthorised] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [recipient, setRecipient] = useState("");

  useEffect(() => {
    doGetGmailToken();
  }, []);

  const doGetGmailToken = async () => {
    const { data: isEmailAuthorised } = await getIsEmailAuthorised(email);
    setIsEmailAuthorised(isEmailAuthorised);
  };

  const doSendAnEmail = async () => {
    const { data, error } = await sendAdminEmail(email, {
      recipients: [recipient],
      title: "Test email",
      bodyTextHtml: `${email} connected`,
    });

    if (error) {
      setStatusMessage(JSON.stringify(error));
      return;
    }
    setStatusMessage(JSON.stringify(data));
  };

  if (!isEmailAuthorised) {
    return (
      <Container>
        <BotTitle>{email}</BotTitle>
        <GmailButton value="Authorize" onClick={() => doGmailLoginAndRedirectToOauth(email)} />
      </Container>
    );
  }

  return (
    <Container>
      <BotTitle>{email}</BotTitle>
      <TextInput title="recipient" value={recipient} onNewInput={newRecipient => setRecipient(newRecipient)} />
      <GmailButton value="Send test email" onClick={doSendAnEmail} />
      <StatusMsg>{statusMessage}</StatusMsg>
    </Container>
  );
};

export default AuthoriseAdminEmail;

import styled from "styled-components";

import { CenteredWithSideNavLayout, Gap } from "components/Layout";
import TextInput from "components/ui/TextInput";
import Button from "components/ui/Button";
import { SmallTitle } from "components/ui/Text";
import { useEffect } from "react";
import { getSeedLinks, postSeedLink, stopSeedLink } from "api/services/datacrawler";
import { useState } from "react";
import { Table, TableCell, TableColumnHeader } from "components/ui/Table";

const Panel = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 20px;
  padding: 20px;
  border: 1px solid ${props => props.theme.color.closer1};
  align-items: end;
  border-radius: 10px;
`;

const SeedLinksTable = styled(Table)`
  width: 100%;
  overflow: auto;
`;

const SmallButton = styled(Button)`
  min-width: 0;
  padding: 5px 10px;
`;

const ButtonCell = styled(TableCell)`
  min-width: 63px;
`;

const ErrMsg = styled.div`
  grid-column: span 3;
  color: ${props => props.theme.color.error};
`;

const StatusCell = styled(TableCell)`
  color: ${props => {
    if (props.status === "IN_PROGRESS") {
      return props.theme.color.in_progress;
    }
    if (props.status.includes("CANCELLED") || props.status.includes("FAIL")) {
      return props.theme.color.error;
    }
    if (props.status === "DONE") {
      return props.theme.color.success;
    }
  }};
`;

const getSafeValue = val => {
  if (typeof val === "object") {
    return JSON.stringify(val);
  }
  return val;
};

const DataCrawlerPage = () => {
  const [url, setUrl] = useState("");
  const [maxLinks, setMaxLinks] = useState("");
  const [seedLinkItems, setSeedLinkItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    doPopulateSeedLinks();
  }, []);

  const doPopulateSeedLinks = async () => {
    const { data } = await getSeedLinks();
    setSeedLinkItems(data?.items);
  };

  const colNames = Object.keys(seedLinkItems?.[0] || {});

  const doSubmitSeedLink = async () => {
    setIsSubmitting(true);
    const { error } = await postSeedLink({ url, maxLinks });
    setIsSubmitting(false);
    if (error) {
      setError(error);
      return;
    }
    window.location.reload();
  };

  const doStopSeedLink = async seedLinkId => {
    const { error } = await stopSeedLink(seedLinkId);
    if (error) {
      setError(error);
      return;
    }
    window.location.reload();
  };

  return (
    <CenteredWithSideNavLayout>
      <SmallTitle>Submit seed-link</SmallTitle>
      <Gap />
      <Panel>
        <TextInput
          value={url}
          onNewInput={newUrl => setUrl(newUrl)}
          title="url"
          placeholder="https://www.opentable.co.uk/"
        />
        <TextInput
          value={maxLinks}
          onNewInput={newMaxLinks => setMaxLinks(newMaxLinks)}
          title="maxLinks"
          placeholder="1000"
        />
        <Button isDisabled={isSubmitting} value="Submit" onClick={doSubmitSeedLink} />
        <ErrMsg>{error && JSON.stringify(error)}</ErrMsg>
      </Panel>
      <Gap height="40px" />
      <SmallTitle>seed-links</SmallTitle>
      <Gap />
      <SeedLinksTable numColumns={colNames?.length + 1}>
        {colNames?.length > 0 && <TableColumnHeader />}
        {colNames?.map(colName => (
          <TableColumnHeader>{colName}</TableColumnHeader>
        ))}
        {seedLinkItems?.map(item => {
          return (
            <>
              <ButtonCell>
                {item?.status === "IN_PROGRESS" && (
                  <SmallButton onClick={() => doStopSeedLink(item?.id)} value="Stop" />
                )}
              </ButtonCell>
              {colNames?.map(colName => {
                if (colName === "status") {
                  return <StatusCell status={item?.status}>{item?.status}</StatusCell>;
                }

                return <TableCell>{getSafeValue(item[colName])}</TableCell>;
              })}
            </>
          );
        })}
      </SeedLinksTable>
    </CenteredWithSideNavLayout>
  );
};

export default DataCrawlerPage;

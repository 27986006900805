import { useState } from "react";

import styled from "styled-components";
import SelectInput from "components/ui/SelectInput";
import TextInput from "components/ui/TextInput";
import Button from "components/ui/Button";
import { useEffect } from "react";
import {
  getAvailableTagsForServiceImageName,
  getDeployableServices,
  getModelVersions,
  postDeploymentActionTypeUpdate,
} from "api/services/deployment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getShortCommitAndBuildTimeAndBranchNameFromTag } from "utils/common";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr auto;
  gap: 10px;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 10px;
  border-radius: 5px;
`;

const DeployButton = styled(Button)`
  align-self: end;
`;

const DeployResultMsg = styled.div`
  grid-column: span 4;
  min-height: 16px;
  color: ${props => (props?.isRed ? props.theme.color.error : props.theme.color.success)};
`;

const ImageTagSelect = styled(SelectInput)`
  select {
    font-family: "Courier New", Courier, monospace;
  }
`;

const DeployServicePanel = ({ productEnvName }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [services, setServices] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTagSha, setSelectedTagSha] = useState("");
  const [numReplicas, setNumReplicas] = useState(1);
  const [isDeploying, setIsDeploying] = useState(false);
  const [hasDeployFailed, setHasDeployFailed] = useState(null);
  const [deployResultMsg, setDeployResultMsg] = useState(null);
  const [modelVersions, setModelVersions] = useState([]);
  const [selectedModelVersion, setSelectedModelVersion] = useState("");

  const selectedServiceName = searchParams.get("selectedServiceName");
  const selectedService = services?.find(service => service?.name === selectedServiceName);

  useEffect(() => {
    doPopulateServices();
  }, [productEnvName]);

  useEffect(() => {
    doPopulateTagsAndModelVersions();
    setNumReplicas(selectedService?.replicas || 1);
  }, [selectedService?.id]);

  const doPopulateServices = async () => {
    const { data: services } = await getDeployableServices(productEnvName);
    setServices(services);

    const serviceNamesInEnv = services?.map(service => service?.name);
    if (!serviceNamesInEnv?.includes(selectedServiceName)) {
      searchParams.set("selectedServiceName", services?.[0]?.name);
      navigate(`/deployments?${searchParams.toString()}`);
    }
  };

  const doPopulateTagsAndModelVersions = async () => {
    const { data: availableTags } = await getAvailableTagsForServiceImageName(selectedService?.id);
    setTags(availableTags);
    setSelectedTagSha(selectedService?.tag || availableTags?.[0]?.tag);

    const { data: modelVersions } = await getModelVersions({ productEnvironment: productEnvName });
    setModelVersions(modelVersions);
    setSelectedModelVersion(selectedService?.modelVersion);
  };

  const doDeployService = async () => {
    setDeployResultMsg(null);
    setIsDeploying(true);

    const deploymentActionBody = {
      deployableServiceId: selectedService?.id,
      tag: selectedTagSha,
      type: "UPDATE",
    };
    if (selectedService?.manualScalingAllowed) {
      deploymentActionBody.replicas = numReplicas;
    }
    if (selectedService?.modelVersion) {
      deploymentActionBody.modelVersion = selectedModelVersion;
    }

    const { data, error } = await postDeploymentActionTypeUpdate(deploymentActionBody);
    setIsDeploying(false);

    if (!error) {
      setDeployResultMsg(JSON.stringify(data));
      window.location.reload();
      return;
    }

    setHasDeployFailed(true);
    setDeployResultMsg(JSON.stringify(error));
  };

  return (
    <Container>
      <SelectInput
        title="service name"
        value={selectedServiceName}
        onSetNewValue={newServiceName => {
          searchParams.set("selectedServiceName", newServiceName);
          navigate(`/deployments?${searchParams.toString()}`);
        }}
      >
        {services?.map(service => (
          <option key={service?.name}>{service?.name}</option>
        ))}
      </SelectInput>
      <ImageTagSelect title="tag" value={selectedTagSha} onSetNewValue={newTagSha => setSelectedTagSha(newTagSha)}>
        {tags?.map(tag => {
          return (
            <option key={tag?.tag} value={tag?.tag}>
              {getShortCommitAndBuildTimeAndBranchNameFromTag(tag?.tag)}
            </option>
          );
        })}
      </ImageTagSelect>
      <TextInput
        isDisabled={!selectedService?.manualScalingAllowed}
        type="number"
        title="Num replicas"
        value={numReplicas}
        onNewInput={r => setNumReplicas(r)}
      />
      {selectedService?.modelVersion ? (
        <SelectInput
          title="model version"
          value={selectedModelVersion}
          onSetNewValue={newModelVersion => setSelectedModelVersion(newModelVersion)}
        >
          {modelVersions?.map(version => (
            <option>{version}</option>
          ))}
        </SelectInput>
      ) : (
        <div />
      )}
      <DeployButton
        isDisabled={isDeploying || !selectedService?.id || !selectedTagSha}
        value="Update"
        onClick={doDeployService}
      />
      {deployResultMsg && <DeployResultMsg isRed={hasDeployFailed}>{deployResultMsg}</DeployResultMsg>}
    </Container>
  );
};

export default DeployServicePanel;

import * as filesBackend from "api/backend/filesEndpoints";

export const getFiles = async () => {
  const { data, error } = await filesBackend.getFiles();
  return { data, error };
};

export const getFile = async fileId => {
  const { data, error } = await filesBackend.getFile(fileId);
  return { data, error };
};

export const postFile = async file => {
  const { data, error } = await filesBackend.postFile(file);
  return { data, error };
};

export const getFileResults = async fileId => {
  const { data, error } = await filesBackend.getFileResults(fileId);
  return { data, error };
};

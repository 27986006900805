import { zapiGet, zapiPostFile } from "api/zapi";

export const getFiles = async () => {
  const { data, error } = await zapiGet(`/playground/api/v1/files`);
  return { data, error };
};

export const getFile = async fileId => {
  const { data, error } = await zapiGet(`/playground/api/v1/files/${fileId}`);
  return { data, error };
};

export const postFile = async file => {
  const { data, error } = await zapiPostFile(`/playground/api/v1/files`, {}, file);
  return { data, error };
};

export const getFileResults = async fileId => {
  const { data, error } = await zapiGet(`/playground/api/v1/files/${fileId}/results`);
  return { data, error };
};

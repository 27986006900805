import styled from "styled-components";

const TextContainer = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding: 5px;
  border-radius: 5px;
  font-size: 16px;
`;

const TextResult = ({ textItem }) => {
  return <TextContainer>{textItem?.content}</TextContainer>;
};

export default TextResult;

import { useState, useEffect } from "react";
import styled from "styled-components";

import { CenteredWithSideNavLayout, Gap } from "components/Layout";
import Button from "components/ui/Button";
import SelectInput from "components/ui/SelectInput";
import { getProductEnvironments } from "api/services/deployment";
import DeployServicePanel from "components/widgets/DeployServicePanel";
import DeployableServicesTable from "components/widgets/DeployableServicesTable";
import { SmallTitle } from "components/ui/Text";
import { useNavigate, useSearchParams } from "react-router-dom";

const EnvSelectInput = styled(SelectInput)`
  width: 200px;
`;

const DeploymentsPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [productEnvNames, setProductEnvNames] = useState([]);

  const selectedProductEnvName = searchParams.get("productEnvName");

  useEffect(() => {
    doPopulateProductEnvNames();
  }, []);

  const doPopulateProductEnvNames = async () => {
    const { data: productEnvs } = await getProductEnvironments();

    const fetchedProductEnvNames = productEnvs?.map(productEnv => productEnv?.name);
    setProductEnvNames(fetchedProductEnvNames);

    if (!selectedProductEnvName) {
      searchParams.set("productEnvName", fetchedProductEnvNames?.[0]);
      navigate(`/deployments?${searchParams.toString()}`);
    }
  };

  if (!selectedProductEnvName) {
    return <CenteredWithSideNavLayout>Fetching product environments...</CenteredWithSideNavLayout>;
  }

  return (
    <CenteredWithSideNavLayout>
      <Button value="New service" />
      <Gap />
      <EnvSelectInput
        isDisabled={!productEnvNames?.length}
        title="product env"
        value={selectedProductEnvName}
        onSetNewValue={newProductEnvName => {
          searchParams.set("productEnvName", newProductEnvName);
          navigate(`/deployments?${searchParams.toString()}`);
        }}
      >
        {productEnvNames?.map(productEnvNameOption => (
          <option key={productEnvNameOption}>{productEnvNameOption}</option>
        ))}
      </EnvSelectInput>
      <Gap height="60px" />
      <DeployServicePanel productEnvName={selectedProductEnvName} />
      <Gap />
      <SmallTitle>Deployable services</SmallTitle>
      <Gap />
      <DeployableServicesTable productEnvName={selectedProductEnvName} />
      <Gap height="40px" />
    </CenteredWithSideNavLayout>
  );
};

export default DeploymentsPage;

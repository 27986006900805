import { getHandlingFromError } from "api/error-handling";
import * as backend from "api/backend/datacrawlerEndpoints";

export const getSeedLinks = async () => {
  const { data, error } = await backend.getSeedLinks();
  return { data, error: getHandlingFromError(error) };
};

export const postSeedLink = async ({ url, maxLinks }) => {
  const { data, error } = await backend.postSeedLink({ url, maxLinks: maxLinks || 1000 });
  return { data, error: getHandlingFromError(error) };
};

export const stopSeedLink = async id => {
  const { data, error } = await backend.patchSeedLink(id, {
    status: "DONE_CANCELLED",
  });
  return { data, error: getHandlingFromError(error) };
};

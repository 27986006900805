import { Gap } from "components/Layout";
import styled from "styled-components";

const Container = styled.div``;

const TableTitle = styled.div`
  font-weight: bold;
`;

const TableCells = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.numCols}, auto);
  border-top: 1px solid ${props => props.theme.color.closer1};
  border-left: 1px solid ${props => props.theme.color.closer1};
  overflow-x: scroll;
`;

const HeaderCell = styled.div`
  padding: 5px 0;
  font-weight: bold;
  border-right: 1px solid ${props => props.theme.color.closer1};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const Cell = styled.div`
  overflow: auto;
  min-width: 100px;
  padding: 5px 0;
  border-right: 1px solid ${props => props.theme.color.closer1};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const getSafeValue = val => {
  if (typeof val === "object") {
    return JSON.stringify(val);
  }
  return val;
};

const Table = ({ name, rows = [] }) => {
  const colNames = Object.keys(rows?.[0] || {});

  return (
    <Container>
      <TableTitle>{name}</TableTitle>
      <Gap height="10px" />
      <TableCells numCols={colNames?.length}>
        {colNames.map(colName => (
          <HeaderCell key={colName}>{colName}</HeaderCell>
        ))}
        {rows.map((row, i) =>
          colNames.map(colName => <Cell key={`${colName}-${i}`}>{getSafeValue(row[colName])}</Cell>)
        )}
      </TableCells>
    </Container>
  );
};

export default Table;

import { getFile } from "api/services/filesService";
import { useState, useEffect } from "react";

const usePollFileStatus = (fileId, initialStatus = "REGISTERED") => {
  const [status, setStatus] = useState(initialStatus);
  const [pollIntervalId, setPollIntervalId] = useState(null);

  useEffect(() => {
    if (status === "READY") {
      return;
    }

    const intervalId = setInterval(doPopulateFileStatus, 2000);
    setPollIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [fileId]);

  useEffect(() => {
    if (status === "READY") {
      clearInterval(pollIntervalId);
    }
  }, [status]);

  const doPopulateFileStatus = async () => {
    const { data } = await getFile(fileId);
    setStatus(data?.status);
  };

  return status;
};

export default usePollFileStatus;

import { zapiGet, zapiPatch, zapiPost } from "api/zapi";

export const getSeedLinks = async () => {
  const { data, error } = await zapiGet("/playground/datacrawler/api/v1/seed-links");
  return { data, error };
};

export const postSeedLink = async body => {
  const { data, error } = await zapiPost("/playground/datacrawler/api/v1/seed-links", null, body);
  return { data, error };
};

export const patchSeedLink = async (id, body) => {
  const { data, error } = await zapiPatch(`/playground/datacrawler/api/v1/seed-links/${id}`, null, body);
  return { data, error };
};

export const errorTypes = {
  // low-level erros
  UNAUTHORISED: "Unauthorised",
  CONN_REFUSED: "Connection refused",

  // Zapi-level errors
  JSON_PARSE_FAIL: "Json parse fail",

  // service-level logic error
  LOGIN_ERROR: "Login error",
  ZAPI_FAIL: "Zapi fail",
  NO_GITHUB_ACCOUNT: "No Github account integrated",

  // others
  GENERIC: "Generic",
  NOT_FOUND: "not found",
  PAYLOAD_TOO_LARGE: "Payload too large",
};

/* 
returns:

{
  redirectUrl: instructs component to redirect somewhere
  message: instructs components to display a message to user
}
*/
export const getHandlingFromError = error => {
  if (!error) {
    return null;
  }

  const { type, message } = error;
  switch (type) {
    case errorTypes.NO_GITHUB_ACCOUNT:
      return { message: message, type: errorTypes.NO_GITHUB_ACCOUNT };
    case errorTypes.UNAUTHORISED:
      return { redirectUrl: "/login", type: errorTypes.UNAUTHORISED };
    case errorTypes.ZAPI_FAIL:
      return { message: message, type: errorTypes.ZAPI_FAIL };
    case errorTypes.JSON_PARSE_FAIL:
      return { message: message, type: errorTypes.JSON_PARSE_FAIL };
    case errorTypes.LOGIN_ERROR:
      return { message: "Login error, try again", type: errorTypes.LOGIN_ERROR };
    case errorTypes.CONN_REFUSED:
      return { message: `Connection refused on request: ${message}`, type: errorTypes.CONN_REFUSED };
    case errorTypes.GENERIC:
      return { message: `Generic error: ${message}`, type: errorTypes.GENERIC };
    case errorTypes.NOT_FOUND:
      return { redirectUrl: "/404", type: errorTypes.NOT_FOUND, message };
    case errorTypes.PAYLOAD_TOO_LARGE:
      return { type: errorTypes.PAYLOAD_TOO_LARGE, message: "File to upload is too large" };
    default:
      return { message: `Unknown error type: ${message}` };
  }
};

import styled from "styled-components";

export const Table = styled.div`
  display: grid;
  width: max-content;
  grid-template-columns: repeat(${props => props.numColumns || 3}, max-content);
  border-left: 1px solid ${props => props.theme.color.closer1};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

export const TableCell = styled.div`
  width: auto;
  max-height: 100px;
  overflow: hidden;
  padding: 5px;
  border-right: 1px solid ${props => props.theme.color.closer1};
  border-top: 1px solid ${props => props.theme.color.closer1};
`;

export const TableColumnHeader = styled.div`
  font-weight: bold;
  border-right: 1px solid ${props => props.theme.color.closer1};
  border-top: 1px solid ${props => props.theme.color.closer1};
  padding: 10px 5px;
`;

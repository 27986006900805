import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getLoggedInUserName } from "api/services/authenticationService";
import { getUserProfileByUserName } from "api/services/projectService";
import ItemListPopover from "components/ui/ItemListPopover";
import { errorTypes } from "api/error-handling";
import { setLoginTokenInClientStorage } from "utils/auth-utils";

const NavBody = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px 32px;
  color: black;
  height: 40px;
  box-sizing: content-box;
  width: ${props => props.hasStickySearchBar && "100%"};
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  align-items: center;
  background-color: ${props => props.bgColor || props.theme.color.furthest};
  border-bottom: 1px solid ${props => (props.hasStickySearchBar ? props.theme.color.closer1 : "transparent")};
`;

const LinksContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const PopoverNavLink = styled(Link)`
  padding: 10px 100px 10px 10px;
  display: block;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  white-space: nowrap;
  transition: background-color 0.2s;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const UserAvatar = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 20px;
  border: 2px solid ${props => props.theme.color.primary};
`;

const LoginDiv = styled.div`
  color: black;
  font-weight: bold;
  :hover {
    cursor: pointer;
  }
`;

const NavHeader = ({ bgColor }) => {
  const userName = getLoggedInUserName();
  const [avatarSrc, setAvatarSrc] = useState(null);

  const doFetchCreatorAvatar = async () => {
    const { data, error } = await getUserProfileByUserName(userName);
    if (error?.type === errorTypes?.UNAUTHORISED || !userName) {
      setLoginTokenInClientStorage("PUBLIC");
      window.location = "/login";
    }
    setAvatarSrc(data?.image?.includes("/images/") ? data?.image : `data:image/png;base64,${data?.image}`);
  };

  useEffect(() => {
    doFetchCreatorAvatar();
  }, [userName]);

  return (
    <>
      <NavBody bgColor={bgColor}>
        {userName && <div>Hello, {userName}</div>}
        <LinksContainer>
          {userName ? (
            <>
              <ItemListPopover iconComponent={<UserAvatar src={avatarSrc} />}>
                <PopoverNavLink to={"/"}>My Files</PopoverNavLink>
                <PopoverNavLink
                  to={"/"}
                  onClick={() => {
                    setLoginTokenInClientStorage("PUBLIC");
                    window.location = "/";
                  }}
                >
                  Logout
                </PopoverNavLink>
              </ItemListPopover>
            </>
          ) : (
            <LoginDiv onClick={() => (window.location = "/login")}>Login</LoginDiv>
          )}
        </LinksContainer>
      </NavBody>
    </>
  );
};

export default NavHeader;

import { BigTitle } from "components/ui/Text";
import { useState } from "react";
import styled from "styled-components";

const Box = styled.div`
  display: block;
  padding: 10px;
  border: 1px dashed ${props => props.theme.color.closer1_5};
  border-radius: 5px;
  background-color: ${props => props.theme.color.closer0};
  width: 100%;
  min-height: 20px;
  margin-bottom: 10px;
`;

const Key = styled.span`
  color: ${props => props.theme.color.closer2};
`;

const Img = styled.img`
  width: 100px;
`;

const CardsContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
`;

const Card = styled.div`
  display: block;
  padding: 10px;
  border-radius: 5px;
  background-color: ${props => props.theme.color.closer1};
  min-height: 20px;
  transition: background-color 0.2s;

  :hover {
    background-color: ${props => props.theme.color.closer1_5};
  }
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.numColumns || 3}, minmax(150px, auto));
  border-left: 1px solid ${props => props.theme.color.closer2};
  border-bottom: 1px solid ${props => props.theme.color.closer2};
`;

const TableCell = styled.div`
  max-height: 100px;
  overflow: hidden;
  padding: 5px;
  background-color: ${props => props.theme.color.closer1};
  border-right: 1px solid ${props => props.theme.color.closer2};
  border-top: 1px solid ${props => props.theme.color.closer2};
`;

const TableColumnHeader = styled.div`
  font-weight: bold;
  border-right: 1px solid ${props => props.theme.color.closer2};
  border-top: 1px solid ${props => props.theme.color.closer2};
  padding: 10px 5px;
`;

const JsonTextInput = ({ initialValue }) => {
  const [value, setValue] = useState(initialValue);

  return <input value={value} onChange={e => setValue(e?.target?.value)} />;
};

const drawObject = obj => {
  if (!obj) {
    return null;
  }

  if (typeof obj === "number" || typeof obj === "string") {
    return `${obj}`?.slice(0, 64);
  }

  if (obj?._url) {
    return <a href={obj._url}>{drawObject(obj._value)}</a>;
  }

  if (obj?._src) {
    if (obj?._src?.includes("/images/") || obj?._src?.includes("avatars")) {
      return <Img src={obj?._src} />;
    }
    return <Img src={`data:image/png;base64,${obj?._src}`} />;
  }

  if (obj?._renderAs === "cards") {
    return (
      <CardsContainer>
        {obj?._items?.map(item => (
          <Card>
            {Object.keys(item)
              .filter(key => key[0] !== "_")
              .map(key => {
                return (
                  <div>
                    <Key>{key}:</Key> {drawObject(item[key])}
                  </div>
                );
              })}
          </Card>
        ))}
      </CardsContainer>
    );
  }

  if (obj?._renderAs === "textinput") {
    return <JsonTextInput initialValue={obj?._value} />;
  }

  if (obj?._renderAs === "table" && Array.isArray(obj?._rows)) {
    const rowWithMostCols = obj?._rows?.reduce((prevRow, row) =>
      Object?.keys(row)?.length > Object?.keys(prevRow)?.length ? row : prevRow
    );
    const columnNames = Object.keys(rowWithMostCols);
    return (
      <Table numColumns={columnNames?.length}>
        {columnNames?.map(colName => (
          <TableColumnHeader>{colName}</TableColumnHeader>
        ))}
        {obj?._rows?.map(row => columnNames?.map(colName => <TableCell>{drawObject(row[colName])}</TableCell>))}
      </Table>
    );
  }

  if (obj?._renderAs === "button") {
    return <button onClick={() => console.log(obj?._onClick)}>{obj?._label}</button>;
  }

  if (obj?._is_editable) {
    return (
      <Box href={obj?.url}>
        {Object.keys(obj)
          .filter(key => key[0] !== "_")
          .map(key => {
            if (obj[key]?._title) {
              return (
                <div>
                  <BigTitle>{obj[key]?._title}</BigTitle>
                  {drawObject(obj[key]?._value)}
                </div>
              );
            }

            return (
              <div>
                <Key>{key}:</Key> {drawObject(obj[key])}
              </div>
            );
          })}
        <button
          onClick={async () => {
            // collect values from obj
            // PUT newObj
          }}
        >
          Save
        </button>
      </Box>
    );
  }

  if (Array.isArray(obj)) {
    return obj.map(item => <div>{drawObject(item)}</div>);
  }

  return (
    <Box href={obj?.url}>
      {Object.keys(obj)
        .filter(key => key[0] !== "_")
        .map(key => {
          if (obj[key]?._title) {
            return (
              <div>
                <BigTitle>{obj[key]?._title}</BigTitle>
                {drawObject(obj[key]?._value)}
              </div>
            );
          }

          return (
            <div>
              <Key>{key}:</Key> {drawObject(obj[key])}
            </div>
          );
        })}
    </Box>
  );
};

const FeJsonViewer = ({ json }) => {
  return <div>{drawObject(json)}</div>;
};

export default FeJsonViewer;

import { zapiDelete, zapiGet, zapiPatch, zapiPost } from "api/zapi";

export const getExcelWorkerConfigs = async () => {
  const { data, error } = await zapiGet(`/playground/api/v1/boltzflow/dev/excel-worker-configs`);
  return { data, error };
};

export const getExcelWorkers = async () => {
  const { data, error } = await zapiGet(`/playground/api/v1/boltzflow/dev/excel-workers`);
  return { data, error };
};

export const getExcelWorkersDedicated = async () => {
  const { data, error } = await zapiGet(`/playground/api/v1/boltzflow/dev/excel-workers/dedicated`);
  return { data, error };
};

export const getNodePools = async () => {
  const { data, error } = await zapiGet(`/playground/api/v1/boltzflow/dev/node-pools`);
  return { data, error };
};

export const patchExcelWorkerConfig = async (params, body) => {
  const { error } = await zapiPatch(`/playground/api/v1/boltzflow/dev/excel-worker-configs`, params, body);
  return { error };
};

export const deleteKillWorker = async workerId => {
  const { error } = await zapiDelete(`/playground/api/v1/boltzflow/dev/excel-workers/${workerId}/kill`);
  return { error };
};

export const postDedicatedExcelWorkerForUser = async userId => {
  const { error } = await zapiPost(`/playground/api/v1/boltzflow/dev/excel-workers/${userId}/dedicated`);
  return { error };
};

export const deleteKillDedicatedWorker = async userId => {
  const { error } = await zapiDelete(`/playground/api/v1/boltzflow/dev/excel-workers/${userId}/kill/dedicated`);
  return { error };
};

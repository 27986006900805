import styled from "styled-components";
import { useState } from "react";

const Container = styled.div`
  ${props => props.isDisabled && "opacity: 0.1; pointer-events: none;"}
  width: 100%;
`;

const SelectContainer = styled.label`
  background-color: ${props => props.theme.color.furthest};
  width: 100%;
  display: block;
  border: 1px solid ${props => (props.isFocused ? props.theme.color.primary : props.theme.color.closer1)};
  border-radius: 60px;
  transition: border-color 0.2s;
  padding-right: 15px;
`;

const Select = styled.select`
  outline: none;
  color: ${props => props.theme.color.closest};
  background-color: ${props => props.theme.color.furthest};
  width: 100%;
  border: none;
  padding: 10px 0px 10px 15px;
  border-radius: 60px;
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
`;

const InputTitle = styled.label`
  display: block;
  color: ${props => props.theme.color.closest};
  font-weight: bold;
  padding-bottom: 10px;
`;

const SelectInput = ({ children, title, value, onSetNewValue, className, isDisabled, fontSize }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Container isDisabled={isDisabled} className={className}>
      {title && <InputTitle>{title}</InputTitle>}
      <SelectContainer isFocused={isFocused}>
        <Select
          fontSize={fontSize}
          value={value || ""}
          onChange={e => onSetNewValue(e.target.value)}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
        >
          {children}
        </Select>
      </SelectContainer>
    </Container>
  );
};

export default SelectInput;

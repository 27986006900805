import { CenteredWithSideNavLayout } from "components/Layout";
import { useState } from "react";
import styled from "styled-components";

import GmailButton from "components/ui/GmailButton";
import { sendAdminEmail } from "api/services/authenticationService";
import TextInput from "components/ui/TextInput";

const Container = styled.div`
  width: 600px;
  display: grid;
  gap: 10px;
`;

const StatusMsg = styled.div``;

const TextArea = styled.textarea`
  min-height: 200px;
`;

const TextAreaTitle = styled.div`
  font-weight: bold;
`;

const EmailTestPage = () => {
  const [statusMessage, setStatusMessage] = useState("");
  const [recipient, setRecipient] = useState("");
  const [bodyTextHtml, setBodyTextHtml] = useState("");
  const [title, setTitle] = useState("test email");

  const doSendAnEmail = async () => {
    const { error } = await sendAdminEmail("demo@boltzbit.com", {
      recipients: [recipient],
      title,
      bodyTextHtml,
    });

    if (error) {
      setStatusMessage(JSON.stringify(error));
      return;
    }
    setStatusMessage(`Email to ${recipient} sent`);
  };

  return (
    <CenteredWithSideNavLayout>
      <Container>
        <TextInput title="recipient" value={recipient} onNewInput={newRecipient => setRecipient(newRecipient)} />
        <TextInput title="title" value={title} onNewInput={newTitle => setTitle(newTitle)} />
        <TextAreaTitle>bodyText</TextAreaTitle>
        <TextArea value={bodyTextHtml} onChange={e => setBodyTextHtml(e?.target?.value)} />
        <GmailButton value="Send an email" onClick={doSendAnEmail} />
        <StatusMsg>{statusMessage}</StatusMsg>
      </Container>
    </CenteredWithSideNavLayout>
  );
};

export default EmailTestPage;

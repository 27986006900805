import NavHeader from "components/widgets/NavHeader";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SideNavContainer = styled.div`
  background-color: ${props => props.theme.color.closer0};
`;

const LogoAndTitle = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr;
  gap: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
`;

const LogoImg = styled.img`
  width: 100%;
`;

const NavItem = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${props => (props.$isCurrentRoute ? props.theme.color.closest : props.theme.color.closer2)};
  padding: 10px;
  padding-left: 20px;
  cursor: pointer;
  font-weight: bold;
  background-color: ${props => (props.$isCurrentRoute ? props.theme.color.closer1 : props.theme.color.closer0)};
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const SideNav = () => (
  <SideNavContainer>
    <LogoAndTitle>
      <LogoImg src={"/images/logo-black.png"} alt="logo" />
      Playground
    </LogoAndTitle>
    <Gap />
    <NavItem to="/" $isCurrentRoute={window.location.pathname === "/"}>
      Email bots management
    </NavItem>
    <NavItem to="/email-test" $isCurrentRoute={window.location.pathname === "/email-test"}>
      Email testing env
    </NavItem>
    <NavItem to="/data-crawler" $isCurrentRoute={window.location.pathname === "/data-crawler"}>
      Data crawler
    </NavItem>
    <NavItem to="/user-management" $isCurrentRoute={window.location.pathname === "/user-management"}>
      User management
    </NavItem>
    <NavItem to="/deployments" $isCurrentRoute={window.location.pathname === "/deployments"}>
      Deployments
    </NavItem>
    <NavItem to="/excel-workers" $isCurrentRoute={window.location.pathname === "/excel-workers"}>
      Excel workers
    </NavItem>
  </SideNavContainer>
);
const CenteredContentContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr;
  @media (min-width: 1200px) {
    grid-template-columns: auto 1fr ${props => props.centerColumnMaxWidth} 1fr;
  }
  min-height: 100vh;
`;

const Content = styled.div`
  padding-top: 50px;
  grid-column: 3;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
`;

export const CenteredWithSideNavLayout = ({ children, isDisabled, centerColumnMaxWidth = "1150px", className }) => (
  <CenteredContentContainer centerColumnMaxWidth={centerColumnMaxWidth}>
    <NavHeader />
    <SideNav />
    <Content className={className} isDisabled={isDisabled}>
      {children}
    </Content>
  </CenteredContentContainer>
);

export const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: ${props => props.leftWidth || "300px"} ${props => props.rightWidth || "1fr"};
  gap: 40px;
`;

export const ThreeColumnLayout = styled.div`
  display: grid;
  grid-template-columns: ${props => props.leftWidth || "1fr"} ${props => props.middleWidth || "1fr"} ${props =>
      props.rightWidth || "1fr"};
  gap: 40px;
`;

export const Gap = styled.div`
  height: ${props => props.height || "20px"};
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
`;

import { useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import { gmailOauthCallBack } from "api/services/authenticationService";

const sendGmailAccessCodeToBackendAndRelocation = async code => {
  const email = sessionStorage.getItem("tempAdminEmailToAuthorise");

  const { error } = await gmailOauthCallBack(email, code);
  if (error) {
    alert(JSON.stringify(error));
  }
  sessionStorage.removeItem("tempAdminEmailToAuthorise");
  window.location = "/";
};

const OauthCallBackPage = () => {
  const [searchParams] = useSearchParams();
  const { source } = useParams();

  useEffect(() => {
    searchParams.get("code") &&
      source === "gmail" &&
      sendGmailAccessCodeToBackendAndRelocation(searchParams.get("code"));
  }, [searchParams, source]);

  return null;
};

export default OauthCallBackPage;

import { getUserManagementJson } from "api/services/authenticationService";
import FeJsonViewer from "components/FeJsonViewer";
import { CenteredWithSideNavLayout, Gap } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import { useEffect, useState } from "react";

const UserManagamentPage = () => {
  const [userManagementJson, setUserManagementJson] = useState({});

  useEffect(() => {
    doPopulateUserManagementJson();
  }, []);

  const doPopulateUserManagementJson = async () => {
    const { data } = await getUserManagementJson();
    setUserManagementJson(data);
  };

  return (
    <CenteredWithSideNavLayout>
      <BigTitle>User management</BigTitle>
      <Gap />
      <FeJsonViewer json={userManagementJson} />
    </CenteredWithSideNavLayout>
  );
};

export default UserManagamentPage;

import { format } from "date-fns";

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const by = (attribute, ascOrDesc = "desc") => {
  return (resourceA, resourceB) => {
    if (ascOrDesc === "asc") {
      return new Date(resourceA[attribute]) > new Date(resourceB[attribute]) ? 1 : -1;
    }
    return new Date(resourceA[attribute]) > new Date(resourceB[attribute]) ? -1 : 1;
  };
};

export const getObjectWithMostKeys = arrayOfObjets =>
  arrayOfObjets?.reduce((prevRow, row) => (Object?.keys(row)?.length > Object?.keys(prevRow)?.length ? row : prevRow));

export const getShortCommitAndBranchNameFromTag = tag => {
  if (!tag) {
    return "";
  }

  const parts = tag?.split("_");
  const shortCommit = parts?.[0]?.slice(0, 7);
  const branchName = parts?.slice(2)?.join("_");

  return `${shortCommit} ${branchName}`;
};

export const getShortCommitAndBuildTimeAndBranchNameFromTag = tag => {
  if (!tag) {
    return "";
  }

  const parts = tag?.split("_");
  const [commitSha, epochMsStr] = parts;

  const epochMs = parseInt(epochMsStr);
  const builtAtDateStr = epochMs ? format(new Date(epochMs), "yyyy-MM-dd HH:mm:ss") : "";

  const shortCommit = commitSha?.slice(0, 7);
  const branchName = parts?.slice(2)?.join("_");

  return `${shortCommit} (${builtAtDateStr}) ${branchName}`;
};

export const parseJson = str => {
  let result = str;
  try {
    result = JSON.parse(str?.replaceAll("\n", " "));
  } catch {
    result = str;
  }
  if (result === 0) {
    return 0;
  }
  return result || [];
};

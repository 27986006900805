import styled from "styled-components";
import { useState, useEffect, Fragment } from "react";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 10px;
  column-gap: 5px;
  justify-content: start;
  justify-items: start;
  align-items: center;
  background-color: ${props => props.theme.color.closer0};
  width: max-content;
  padding: 10px;
  border-radius: 5px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const FieldName = styled.div`
  font-weight: 600;
`;

const SaveButton = styled.button`
  grid-column: 2;
  font-family: "Montserrat";
  justify-self: end;
`;

const EditableJson = ({
  jsonToEdit = {},
  fieldNameToInput = {
    name: ({ value, onChangeValue }) => (
      <input type="text" value={value} onChange={e => onChangeValue(e.target.value)} />
    ),
  },
  onPressSave,
  isDisabled,
}) => {
  const [editableJson, setEditableJson] = useState(jsonToEdit);

  useEffect(() => {
    setEditableJson(jsonToEdit);
  }, [JSON.stringify(jsonToEdit)]);

  return (
    <Container isDisabled={isDisabled}>
      {Object.entries(editableJson).map(([fieldName, fieldValue]) => {
        if (fieldNameToInput?.[fieldName]) {
          const renderInput = fieldNameToInput[fieldName];
          return (
            <Fragment key={fieldName}>
              <FieldName>{fieldName}</FieldName>
              {renderInput({
                value: fieldValue,
                onChangeValue: newValue => setEditableJson({ ...editableJson, [fieldName]: newValue }),
              })}
            </Fragment>
          );
        }

        return (
          <Fragment key={fieldName}>
            <FieldName>{fieldName}</FieldName>
            <div>{fieldValue}</div>
          </Fragment>
        );
      })}
      <SaveButton onClick={() => onPressSave(editableJson)}>Save</SaveButton>
    </Container>
  );
};

export default EditableJson;

import { getHandlingFromError } from "api/error-handling";
import * as backend from "api/backend/deploymentEndpoints";

export const getProductEnvironments = async () => {
  const { data, error } = await backend.getProductEnvironments();
  return { data, error: getHandlingFromError(error) };
};

export const getDeployableServices = async productEnvName => {
  const { data, error } = await backend.getDeployableServices({ productEnvironment: productEnvName });
  return { data, error: getHandlingFromError(error) };
};

export const getAvailableTagsForServiceImageName = async deployableServiceId => {
  if (!deployableServiceId) {
    return { data: [], error: null };
  }
  const { data, error } = await backend.getAvailableTags({ deployableServiceId });
  return { data, error: getHandlingFromError(error) };
};

export const postDeploymentActionTypeUpdate = async (body = {}) => {
  const { data, error } = await backend.postDeploymentAction(body);
  return { data, error: getHandlingFromError(error) };
};

export const getModelVersions = async params => {
  const { data, error } = await backend.getModelVersions(params);
  return { data, error: getHandlingFromError(error) };
};

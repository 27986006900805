import qs from "qs";
import axios from "axios";
import { errorTypes } from "api/error-handling";
import { getLoginTokenFromClientStorage } from "utils/auth-utils";

const getHeaders = () => {
  const accessToken = getLoginTokenFromClientStorage();
  const headers = { Authorization: `Bearer ${accessToken}` };
  return headers;
};

const axiosInstance = axios.create({
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "comma", encode: false });
  },
});

export const apiGetRawResponse = async (url, params = {}) => {
  try {
    const queryParameters = Object.entries(params).map(([key, value]) => `${key}=${value}`);
    const queryParameterString = queryParameters.join("&");
    const res = await fetch(`${url}?${queryParameterString}`, {
      headers: getHeaders(),
    });
    return res;
  } catch (error) {
    return error.response;
  }
};

export const apiGet = async (url, params = {}) => {
  try {
    const res = await axiosInstance.get(`${url}`, {
      params,
      headers: getHeaders(),
    });
    return { data: res.data, error: null };
  } catch (error) {
    if (error.response.status === 401) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const apiPost = async (url, params = {}, body = {}) => {
  try {
    const res = await axiosInstance.post(`${url}`, body, {
      params,
      headers: getHeaders(),
    });
    return { data: res.data, error: null };
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const apiPatch = async (url, params = {}, body = {}) => {
  try {
    const res = await axiosInstance.patch(`${url}`, body, {
      params,
      headers: getHeaders(),
    });
    return { data: res.data, error: null };
  } catch (error) {
    if (error.response.status === 401) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.status === 404) {
      return { data: null, error: { type: errorTypes.NOT_FOUND, message: url } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const apiDelete = async (url, params = {}, body = {}) => {
  try {
    const res = await axiosInstance.delete(`${url}`, {
      params,
      headers: getHeaders(),
    });
    return { data: res.data, error: null };
  } catch (error) {
    if (error.response.status === 401) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const apiPut = async (url, params = {}, body = {}) => {
  try {
    const res = await axiosInstance.put(`${url}`, body, {
      params,
      headers: getHeaders(),
    });
    return { data: res.data, error: null };
  } catch (error) {
    if (error.response.status === 401) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

export const apiPostFile = async (url, params, file, onUploadProgress) => {
  try {
    let body = new FormData();
    body.append("file", file);
    return await axiosInstance.post(url, body, {
      params,
      headers: getHeaders(),
      onUploadProgress,
    });
  } catch (error) {
    if (error.response.status === 401) {
      return { data: null, error: { type: errorTypes.UNAUTHORISED } };
    }
    if (error.response.status === 413) {
      return { data: null, error: { type: errorTypes.PAYLOAD_TOO_LARGE } };
    }
    if (error.response.data?.message) {
      if (error.response.data.message.includes("Connection refused")) {
        return {
          data: null,
          error: {
            type: errorTypes.CONN_REFUSED,
            message: error.response.data?.path,
          },
        };
      }
      return {
        data: null,
        error: {
          type: errorTypes.GENERIC,
          message: error.response.data?.message,
        },
      };
    }
    return { data: null, error: { type: errorTypes.GENERIC } };
  }
};

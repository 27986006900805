import jwt_decode from "jwt-decode";

import * as backend from "api/backend/authenticationServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { getLoginTokenFromClientStorage } from "utils/auth-utils";

export const DEMO_EMAIL = "demo@boltzbit.com";
export const CUSTOMER_EMAIL = "customer.assistant@boltzbit.com";
export const ASSISTANT_EMAIL = "assistant@boltzbit.com";
export const BOLTZFLOW_EMAIL = "assistant@boltzbit.com (ocr)"

export const getAccessToken = async (username, password) => {
  const { data, error } = await backend.getLoginTokens(username, password);
  return { data, error: getHandlingFromError(error) };
};

export const getLoggedInUserName = () => {
  try {
    const accessToken = getLoginTokenFromClientStorage();
    const { sub } = jwt_decode(accessToken);
    return sub;
  } catch {
    return "";
  }
};

export const getJwtForUserFromOneTimeCode = async (userId, code) => {
  const { data, error } = await backend.getVerifyOneTimeCode(userId, { code });
  return { data, error: getHandlingFromError(error) };
};

export const isUserLoggedIn = () => {
  return !!getLoggedInUserName();
};

export const getRedirectUrlToGoogleLogin = async () => {
  const callbackUrl = `${window.location.origin}/oauth-callback/gmail`;
  const { data, error } = await backend.gmailLogin({ callbackUrl });
  return { data, error: getHandlingFromError(error) };
};

export const getIsEmailAuthorised = async email => {
  let data = null;
  let error = null;

  if (email === DEMO_EMAIL) {
    ({ data, error } = await backend.getTokenBzAdmin());
  }
  if (email === CUSTOMER_EMAIL) {
    ({ data, error } = await backend.getTokenCustomerAdmin());
  }
  if (email === ASSISTANT_EMAIL) {
    ({ data, error } = await backend.getTokenAssistantAdmin());
  }

  if (email === BOLTZFLOW_EMAIL) {
    ({ data, error } = await backend.getTokenOcrAdmin());
  }

  return { data, error: getHandlingFromError(error) };
};

export const sendAdminEmail = async (fromEmail, { recipients = [], title = "", bodyTextHtml = "" }) => {
  let data = null;
  let error = null;

  if (fromEmail === DEMO_EMAIL) {
    ({ data, error } = await backend.sendEmailBzAdmin({ recipients, title, bodyTextHtml }));
  }
  if (fromEmail === CUSTOMER_EMAIL) {
    ({ data, error } = await backend.sendEmailCustomerAdmin({ recipients, title, bodyTextHtml }));
  }
  if (fromEmail === ASSISTANT_EMAIL) {
    ({ data, error } = await backend.sendEmailAssistantAdmin({ recipients, title, bodyTextHtml }));
  }
  if (fromEmail === BOLTZFLOW_EMAIL) {
    ({ data, error } = await backend.sendEmailBoltzflowAdmin({ recipients, title, bodyTextHtml }));
  }

  return { data, error: getHandlingFromError(error) };
};

export const gmailOauthCallBack = async (email, code) => {
  let data = null;
  let error = null;

  if (email === DEMO_EMAIL) {
    ({ data, error } = await backend.getOauthCallbackBzAdmin({ code }));
  }
  if (email === CUSTOMER_EMAIL) {
    ({ data, error } = await backend.getOauthCallbackCustomerAdmin({ code }));
  }
  if (email === ASSISTANT_EMAIL) {
    ({ data, error } = await backend.getOauthCallbackAssistantAdmin({ code }));
  }
  if (email === BOLTZFLOW_EMAIL) {
    ({ data, error } = await backend.getOauthCallbackBoltzflowAdmin({ code, domain: "ocr" }));
  }

  return { data, error: getHandlingFromError(error) };
};

export const getUserManagementJson = async () => {
  const jsonData = {
    userId: "63076bcfeda3ea5b0f81efec",
    activities: {
      _title: "Activities",
      _value: {
        ocr: {
          status: "ACTIVATED",
          activities: {
            _renderAs: "table",
            _rows: [
              {
                name: "Doc uploaded",
                createdAt: "2022-08-25T16:17:44.494Z",
              },
              {
                name: "Annotation added",
                createdAt: "2022-08-25T16:17:44.494Z",
              },
              {
                name: "Annotation removed",
                createdAt: "2022-08-25T16:17:44.494Z",
              },
            ],
          },
        },
        calendar: {
          status: "UNAUTHORISED",
          activities: [],
        },
      },
    },
    support: {
      _title: "Support",
      _value: [
        {
          _renderAs: "button",
          _label: "Reset password",
          _onClick: "POST /api/v1/admin/reset-password { userId: '63076bcfeda3ea5b0f81efec' }",
        },
      ],
    },
  };
  return { data: jsonData };
};
